var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rr-header-new",class:( _obj = {
    'rr-header-new_wide': _vm.isWide,
    'rr-header-new_scrolled': _vm.isScrolled,
    'rr-header-new_themed': !_vm.isDefaultTheme
  }, _obj[("rr-header-new_" + _vm.align)] = _vm.align, _obj )},[_c('header',{staticClass:"rr-header-new__panel"},[_c('div',{staticClass:"rr-header-new__container"},[_c('div',{staticClass:"rr-header-new__col rr-header-new__col_mobile"},[_c('Button',{staticClass:"rr-header-new__toggle",attrs:{"variant":"clear","icon-left":"menu","shape":"square","size":""},on:{"click":_vm.onOpenMenu}},[(_vm.live)?_c('span',{staticClass:"rr-header-new__toggle-dot"}):_vm._e()]),_vm._v(" "),_c('HeaderMobileNav',{attrs:{"nav":_vm.mobileMenu,"liveUrl":_vm.liveUrl,"isLive":_vm.live,"locales":_vm.locales,"locale":_vm.locale},on:{"changeLang":_vm.changeLang},model:{value:(_vm.isOpenedMobileNav),callback:function ($$v) {_vm.isOpenedMobileNav=$$v},expression:"isOpenedMobileNav"}},[_c('template',{slot:"logo"},[_c('a',{attrs:{"href":_vm.logoUrl}},[_c('img',{attrs:{"width":_vm.logoWidth,"height":_vm.logoHeight,"src":_vm.logoImg ? _vm.logoImg : _vm.logoImgRR,"alt":_vm.logoAlt}})])])],2)],1),_vm._v(" "),_c('div',{staticClass:"rr-header-new__col rr-header-new__col_logo"},[(_vm.isDefaultTheme)?_c('div',{staticClass:"rr-header-new__logo"},[_c('a',{staticClass:"rr-header-new__logo-link",attrs:{"href":_vm.logoUrl}},[_c('img',{staticClass:"rr-header-new__logo-symbol",attrs:{"src":_vm.logoImg ? _vm.logoImg : _vm.logoImgRR,"alt":_vm.logoAlt}})])]):_c('a',{staticClass:"rr-header-new__logo",attrs:{"href":_vm.logoUrl}},[_c('img',{attrs:{"width":_vm.logoWidth,"height":_vm.logoHeight,"src":_vm.logoImg ? _vm.logoImg : _vm.logoImgRR,"alt":_vm.logoAlt}})])]),_vm._v(" "),_c('HeaderMenu',{staticClass:"rr-header-new__col rr-header-new__col_nav",attrs:{"menu":_vm.menu,"moreButtonText":_vm.moreButtonText,"live":_vm.live,"liveUrl":_vm.liveUrl}}),_vm._v(" "),_c('div',{staticClass:"rr-header-new__col rr-header-new__col_right rr-header-new__cols"},[_c('LangSwitch',{staticClass:"rr-header-new__cols-item rr-header-new__lang",attrs:{"locales":_vm.locales,"locale":_vm.locale,"dropdownOptions":{
            triggerId: 'headerLangSwitch',
            placement: 'bottom-end',
          },"isFlags":_vm.isFlagsLang},on:{"change":_vm.changeLang},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('Button',{staticClass:"rr-header-new__lang-button",attrs:{"id":"headerLangSwitch","variant":"clear","shape":"square","iconLeft":"global","iconSize":"xl"}})]},proxy:true}])}),_vm._v(" "),_c('HeaderProfile',{staticClass:"rr-header-new__cols-item",attrs:{"user":_vm.user,"profileMenu":_vm.profileMenu,"logoutUrl":_vm.logoutUrl,"logoutText":_vm.logoutText,"loginUrl":_vm.loginUrl,"isAuthorized":_vm.isAuthorized}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"rr-header-new__offset"})])}
var staticRenderFns = []

export { render, staticRenderFns }