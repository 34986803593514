<template>
  <div class="rr-header-profile">
    <a
      v-if="!isAuthorized"
      :href="loginUrl"
      class="rr-header-profile__button"
    >
      <Avatar bordered></Avatar>
    </a>

    <template v-else>
      <button
        ref="avatar"
        class="rr-header-profile__button"
      >
        <Avatar
          :username="user && user.fullName"
          :img="user && user.avatarSmallUrl"
          bordered
        ></Avatar>
      </button>
    </template>

    <Dropdown
      trigger="avatar"
      listeners="hover click"
      placement="bottom-end"
    >
      <template slot="items">
        <div
          v-for="(profileItem, index) in profileMenuAvailable"
          v-show="!profileItem.roles || isInRole(profileItem.roles)"
          :key="index"
          class="base-dropdown__item"
        >
          <div v-if="profileItem.isDivider" class="rr-header-profile__divider"></div>
          <a v-else :href="profileItem.link" class="base-dropdown__link">
            <Icon class="base-dropdown__icon" :name='profileItem.icon'></Icon>
            <span class="base-dropdown__text">{{ profileItem.name }}</span>
          </a>
        </div>
        <div v-show="profileMenuAvailable.length" class="rr-header-profile__divider"></div>
        <div class="base-dropdown__item">
          <a :href="logoutUrl" class="base-dropdown__link">
            <Icon class="base-dropdown__icon" name="logout"></Icon>
            <span class="base-dropdown__text">{{ logoutText }}</span>
          </a>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import Avatar from '@/components/Avatar/Avatar.vue';

export default {
  name: 'HeaderProfile',
  components: {
    Avatar,
    Dropdown,
  },
  props: {
    user: {
      type: Object,
    },
    profileMenu: {
      type: Array,
    },
    logoutUrl: {
      type: String,
    },
    logoutText: {
      type: String,
    },
    isAuthorized: {
      type: Boolean,
      default: false,
    },
    loginUrl: {
      type: String,
    },
  },
  computed: {
    profileMenuAvailable() {
      return this.profileMenu.filter((item) => !item.hidden);
    },
  },
  methods: {
    isInRole(rolesArr) {
      const roles = rolesArr || [];
      return !roles.length || roles.some((role) => this.user[role]);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './HeaderProfile';
</style>
