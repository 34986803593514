<template>
  <transition
    name="nav-transition"
    tag="div"
    mode="out-in"
  >
  <div
    class="rr-mobile-nav"
    :class="{
      'rr-mobile-nav_opened': isOpened,
    }"
    v-show="isOpened"
  >
    <div
      class="rr-mobile-nav__overlay"
      @click="close"
    ></div>
    <div class="rr-mobile-nav__container">
      <div class="rr-mobile-nav__header">
        <slot name="logo">
          <div></div>
        </slot>
        <LivePlate
          v-if="isLive"
          :href="liveUrl" />
        <Button
          class="rr-mobile-nav__close"
          variant="clear"
          shape="square"
          icon-left="close"
          @click="close"
        ></Button>
      </div>
      <div class="rr-mobile-nav__body">
        <template
          v-for="(link, i) in nav"
        >
          <div
            v-if="link.divider"
            class="rr-mobile-nav__divider"
            :key="i"
          ></div>
          <NavigationItem
            v-else
            :key="link.text"
            v-bind="{
              ...link,
              icon: link.icon || 'caret-right',
            }"
          ></NavigationItem>
        </template>
        <template v-if="locales.length > 1">
          <div class="rr-mobile-nav__divider"></div>
          <div class="rr-mobile-nav__footer">
            <LangSwitch
              class="rr-header__cols-item rr-mobile-nav__lang"
              :locales="locales"
              :locale="locale"
              :dropdownOptions="{
                triggerId: 'headerLangSwitchMobile',
                placement: 'top-end',
              }"
              @change="changeLang"
            >
              <template v-slot:default="{ language }">
                <Button
                  id="headerLangSwitchMobile"
                  variant="secondary"
                  :text="language.text"
                  icon-left="global"
                  wide
                ></Button>
              </template>
            </LangSwitch>
          </div>
        </template>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import NavigationItem from '@/components/NavigationItem/NavigationItem.vue';
import LangSwitch from '@/components/LangSwitch/LangSwitch.vue';
import LivePlate from '@/components/LivePlate/LivePlate.vue';

export default {
  name: 'HeaderMobileNav',
  designVersion: '2.0.0',
  designLink: 'https://www.figma.com/file/MAH9taJUL2HzqvGofNpg5Z/Components?node-id=1256%3A67',
  components: {
    NavigationItem,
    LangSwitch,
    LivePlate,
  },
  model: {
    prop: 'isOpened',
    event: 'change',
  },
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
    isOpened: Boolean,
    locales: {
      type: Array,
      default: () => [],
    },
    locale: {
      type: String,
      default: 'ru',
    },
    liveUrl: {
      type: String,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open() {
      this.$emit('change', true);
    },
    close() {
      this.$emit('change', false);
    },
    changeLang(lang) {
      this.$emit('changeLang', lang);
    },
  },
  watch: {
    isOpened: {
      handler(val) {
        document.body.style.overflow = val ? 'hidden' : '';
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./HeaderMobileNav";
</style>
