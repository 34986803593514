var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"nav-transition","tag":"div","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpened),expression:"isOpened"}],staticClass:"rr-mobile-nav",class:{
    'rr-mobile-nav_opened': _vm.isOpened,
  }},[_c('div',{staticClass:"rr-mobile-nav__overlay",on:{"click":_vm.close}}),_vm._v(" "),_c('div',{staticClass:"rr-mobile-nav__container"},[_c('div',{staticClass:"rr-mobile-nav__header"},[_vm._t("logo",function(){return [_c('div')]}),_vm._v(" "),(_vm.isLive)?_c('LivePlate',{attrs:{"href":_vm.liveUrl}}):_vm._e(),_vm._v(" "),_c('Button',{staticClass:"rr-mobile-nav__close",attrs:{"variant":"clear","shape":"square","icon-left":"close"},on:{"click":_vm.close}})],2),_vm._v(" "),_c('div',{staticClass:"rr-mobile-nav__body"},[_vm._l((_vm.nav),function(link,i){return [(link.divider)?_c('div',{key:i,staticClass:"rr-mobile-nav__divider"}):_c('NavigationItem',_vm._b({key:link.text},'NavigationItem',Object.assign({}, link,
            {icon: link.icon || 'caret-right'}),false))]}),_vm._v(" "),(_vm.locales.length > 1)?[_c('div',{staticClass:"rr-mobile-nav__divider"}),_vm._v(" "),_c('div',{staticClass:"rr-mobile-nav__footer"},[_c('LangSwitch',{staticClass:"rr-header__cols-item rr-mobile-nav__lang",attrs:{"locales":_vm.locales,"locale":_vm.locale,"dropdownOptions":{
              triggerId: 'headerLangSwitchMobile',
              placement: 'top-end',
            }},on:{"change":_vm.changeLang},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var language = ref.language;
return [_c('Button',{attrs:{"id":"headerLangSwitchMobile","variant":"secondary","text":language.text,"icon-left":"global","wide":""}})]}}],null,false,240480765)})],1)]:_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }