<template>
  <div
    class="rr-header-new"
    :class="{
      'rr-header-new_wide': isWide,
      'rr-header-new_scrolled': isScrolled,
      'rr-header-new_themed': !isDefaultTheme,
      [`rr-header-new_${align}`]: align,
    }"
  >
    <header class="rr-header-new__panel">
      <div class="rr-header-new__container">
        <div class="rr-header-new__col rr-header-new__col_mobile">
          <Button
            class="rr-header-new__toggle"
            variant="clear"
            icon-left="menu"
            shape="square"
            size=""
            @click="onOpenMenu"
          >
            <span v-if="live" class="rr-header-new__toggle-dot"></span>
          </Button>
          <HeaderMobileNav
            v-model="isOpenedMobileNav"
            :nav="mobileMenu"
            :liveUrl="liveUrl"
            :isLive="live"
            :locales="locales"
            :locale="locale"
            @changeLang="changeLang"
          >
            <template slot="logo">
              <a :href="logoUrl">
                <img :width="logoWidth" :height="logoHeight" :src="logoImg ? logoImg : logoImgRR" :alt="logoAlt">
              </a>
            </template>
          </HeaderMobileNav>
        </div>

        <div class="rr-header-new__col rr-header-new__col_logo">
          <div v-if="isDefaultTheme" class="rr-header-new__logo">
            <a
              :href="logoUrl"
              class="rr-header-new__logo-link"
            >
              <img
                :src="logoImg ? logoImg : logoImgRR"
                :alt="logoAlt"
                class="rr-header-new__logo-symbol"
              >
            </a>
          </div>
          <a v-else :href="logoUrl" class="rr-header-new__logo">
            <img
              :width="logoWidth"
              :height="logoHeight"
              :src="logoImg ? logoImg : logoImgRR"
              :alt="logoAlt"
            >
          </a>
        </div>

        <HeaderMenu
          :menu="menu"
          :moreButtonText="moreButtonText"
          :live="live"
          :liveUrl="liveUrl"
          class="rr-header-new__col rr-header-new__col_nav"
        ></HeaderMenu>

        <div class="rr-header-new__col rr-header-new__col_right rr-header-new__cols">
          <LangSwitch
            class="rr-header-new__cols-item rr-header-new__lang"
            :locales="locales"
            :locale="locale"
            :dropdownOptions="{
              triggerId: 'headerLangSwitch',
              placement: 'bottom-end',
            }"
            :isFlags="isFlagsLang"
            @change="changeLang"
          >
            <template v-slot:default>
              <Button
                id="headerLangSwitch"
                variant="clear"
                shape="square"
                iconLeft="global"
                iconSize="xl"
                class="rr-header-new__lang-button"
              >
              </Button>
            </template>
          </LangSwitch>

          <HeaderProfile
            :user="user"
            :profileMenu="profileMenu"
            :logoutUrl="logoutUrl"
            :logoutText="logoutText"
            :loginUrl="loginUrl"
            :isAuthorized="isAuthorized"
            class="rr-header-new__cols-item"
          ></HeaderProfile>
        </div>
      </div>
    </header>
    <div class="rr-header-new__offset"></div>
  </div>
</template>

<script>
import LangSwitch from '@/components/LangSwitch/LangSwitch.vue';
import LogoImg from '@/assets/img/logo.svg';
import HeaderMobileNav from '../HeaderMobileNav/HeaderMobileNav.vue';
import HeaderMenu from './HeaderMenu.vue';
import HeaderProfile from './HeaderProfile.vue';

export default {
  name: 'Header',
  components: {
    HeaderMobileNav,
    HeaderMenu,
    HeaderProfile,
    LangSwitch,
  },
  props: {
    isWide: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'rr',
    },
    logoImg: {
      type: String,
    },
    logoAlt: {
      type: String,
    },
    logoWidth: {
      type: String,
      default: 'auto',
    },
    logoHeight: {
      type: String,
      default: 'auto',
    },
    logoUrl: {
      type: String,
      default: '/',
    },
    loginUrl: {
      type: String,
    },
    loginText: {
      type: String,
    },
    user: {
      type: Object,
    },
    menu: {
      type: Array,
    },
    mobileMenu: {
      type: Array,
    },
    profileMenu: {
      type: Array,
    },
    state: {
      type: String,
      default: 'unauthorized',
      validator(value) {
        return ['', 'unauthorized', 'authorized'].indexOf(value) !== -1;
      },
    },
    live: {
      type: Boolean,
    },
    liveUrl: {
      type: String,
    },
    moreButtonText: {
      type: String,
    },
    locales: {
      type: Array,
    },
    locale: {
      type: String,
      default: 'ru',
    },
    logoutUrl: {
      type: String,
    },
    logoutText: {
      type: String,
    },
    align: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'center'].indexOf(value) !== -1;
      },
    },
    isFlagsLang: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      logoImgRR: LogoImg,
      isScrolled: false,
      isOpenedMobileNav: false,
    };
  },
  computed: {
    isAuthorized() {
      return this.state === 'authorized';
    },
    isDefaultTheme() {
      return this.theme === 'rr';
    },
  },
  methods: {
    onCloseMenu() {
      this.isOpenedMobileNav = false;
    },
    onOpenMenu() {
      this.isOpenedMobileNav = true;
    },
    changeLang(lang) {
      this.$emit('changeLang', lang);
    },
    setScrolled() {
      this.isScrolled = window.pageYOffset > 10;
    },
  },
  mounted() {
    document.removeEventListener('scroll', this.setScrolled);
    document.addEventListener('scroll', this.setScrolled);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Header";
</style>
