<template>
  <a
    class="live-plate"
    :href="href"
    target="_blank">
    LIVE
  </a>
</template>

<script>
export default {
  name: 'LivePlate',
  props: {
    href: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './LivePlate.scss';
</style>
