<template>
  <nav ref="nav" class="rr-header-nav">
    <div
      ref="navContainer"
      class="rr-header-nav__container"
      :class="{'transparent': !menuUpdated}"
    >
      <div
        v-for="menuItem in menu"
        :key="menuItem.id"
        class="rr-header-nav__item"
      >
        <a
          :id="`menu-${menuItem.id}`"
          :href="typeof menuItem.action === 'function' ? '#' : menuItem.href"
          :target="menuItem.target ? menuItem.target : ''"
          class="rr-header-nav__link"
          :class="menuItem.active && 'active'"
          v-html="menuItem.text"
          @click="onNavClick($event, menuItem)"
        >
        </a>
        <Dropdown
          v-if="menuItem.items && !menuItem.itemsOnlyMobile"
          :triggerId="`menu-${menuItem.id}`"
          listeners="hover"
          placement="bottom-start"
          hide-overlay
          auto-width
        >
          <template slot="items">
            <div
              class="base-dropdown__item"
              v-for="(menuSubItem, indexSubItem) in menuItem.items"
              :key="indexSubItem"
            >
              <a
                :href="menuSubItem.href"
                :target="menuSubItem.target ? menuSubItem.target : ''"
                class="base-dropdown__link"
              >
                <span class="base-dropdown__text" v-html="menuSubItem.text"></span>
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="rr-header-nav__item" v-show="menuHidden.length">
        <Link
          ref="more"
          :text="moreButtonText"
          href="#"
          variant="inherit"
          icon-right="down"
          class="rr-header-nav__more"
        />
        <Dropdown
          trigger="more"
          listeners="hover click"
          placement="bottom"
          hide-overlay
          auto-width
        >
          <template slot="items">
            <div
              class="base-dropdown__item"
              v-for="(menuItem, i) in menuHidden"
              :key="i"
            >
              <a
                :href="typeof menuItem.action === 'function' ? '#' : menuItem.action"
                :target="menuItem.target ? menuItem.target : ''"
                class="base-dropdown__link"
                @click="onNavClick($event, menuItem)"
              >
                <span class="base-dropdown__text" v-html="menuItem.text"></span>
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
      <div v-if="live" class="rr-header-nav__item">
        <LivePlate :href="liveUrl" />
      </div>
    </div>
  </nav>
</template>

<script>
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import LivePlate from '@/components/LivePlate/LivePlate.vue';

export default {
  name: 'HeaderMenu',
  components: {
    Dropdown,
    LivePlate,
  },
  props: {
    menu: Array,
    moreButtonText: String,
    live: Boolean,
    liveUrl: String,
  },
  data() {
    return {
      menuHidden: [],
      menuItemsWidth: [],
      menuUpdated: false,
    };
  },
  methods: {
    updateNav() {
      if (window.innerWidth < 1024) {
        this.menuUpdated = true;
        return;
      }

      const { nav } = this.$refs;
      const container = this.$refs.navContainer;

      if (!container) {
        this.menuUpdated = true;
        return;
      }

      const availableSpace = nav.offsetWidth;
      const widths = this.menuItemsWidth;

      if (container.offsetWidth > availableSpace) {
        widths.push(container.offsetWidth);
        const link = this.menu.splice(this.menu.length - 1);
        this.menuHidden.unshift(link[0]);
      } else if (availableSpace > widths[widths.length - 1]) {
        const link = this.menuHidden.splice(0, 1);
        this.menu.push(link[0]);
        widths.pop();
      }

      setTimeout(() => {
        if (container.offsetWidth > availableSpace) {
          this.updateNav();
        }
        this.menuUpdated = true;
      });
    },
    checkNav() {
      setTimeout(() => {
        this.updateNav();
        window.addEventListener('resize', this.updateNav);
      }, 10);
    },
    setActiveMenu() {
      for (let i = 0; i < this.menu.length; i += 1) {
        if (this.menu[i].href === document.location.pathname) {
          this.menu[i].active = true;
        }
      }
    },
    onMenuClick(e, item) {
      if (item.items) {
        e.preventDefault();
        // eslint-disable-next-line no-param-reassign
        item.open = !item.open;
        return;
      }
      if (item.action) {
        e.preventDefault();
        item.action();
      }
    },
    onNavClick(e, item) {
      if (item.action) {
        if (typeof item.action === 'function') {
          e.preventDefault();
          item.action();
        }
      }
    },
  },
  watch: {
    $route() {
      document.body.style.overflow = '';
    },
    mobileMenuOpen(val) {
      document.body.style.overflow = val ? 'hidden' : '';
    },
    isAuthorized: {
      handler(val) {
        if (val) {
          this.updateNav();
        }
      },
      immediate: true,
    },
    live(val) {
      if (!this.isMobile && val) {
        this.updateNav();
      }
    },
  },
  mounted() {
    this.setActiveMenu();
    this.checkNav();
  },
};
</script>

<style lang="scss" scoped>
  @import './HeaderMenu';
</style>
